import React, { useState, useEffect, useMemo } from "react";
import {
  Container, Row, Col, Table,
  Input, Button, FormGroup, Label,
  Card, CardHeader, CardTitle, CardBody,
  Form, FormFeedback, Spinner,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfo, faSearch, faSync, faUsers, faFileExcel, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import Projects from "../projects/Projects";
import projectUsersService from "../../services/api/project-users-service";
import projectsService from "../../services/api/projects-service";
import moment from 'moment';
const UserProfileDashboard = (props) => {
  const projectId = new URLSearchParams(props.location.search).get("projectid");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [tokenDetails, setTokenDetails] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [exportFields, setExportFields] = useState(["name", "createdAt"]);
  const [exportError, setExportError] = useState("");
  const [filters, setFilters] = useState({
    email: "",
    first_name: "",
    last_name: "",
    start_date: null,
    end_date: null,
  });
  const [projectDetails, setProjectDetails] = useState({});
  const [selectedUser, setSelectedUser] = useState(null)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0
  });

  const availableExportFields = [
    { label: "Full Name", key: "name" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email Address", key: "email" },
    { label: "Contact Number", key: "phone" },
    { label: "Created At", key: "createdAt" },

  ];

  const filterKey = useMemo(() =>
    JSON.stringify({
      email: filters.email,
      first_name: filters.first_name,
      last_name: filters.last_name,
      start_date: filters.start_date,
      end_date: filters.end_date,
      page: pagination.currentPage
    }),
    [filters, pagination.currentPage]
  );

  const firstRecord = (pagination.currentPage - 1) * pagination.pageSize + 1;
  const lastRecord = Math.min(
    pagination.currentPage * pagination.pageSize,
    pagination.totalItems
  );


  useEffect(() => {
    (
      async () => {
        try {
          const result = await projectsService.getProjectDetails(projectId);
          if (result && result.status === true) {
            setProjectDetails(result.data)
          }
        } catch (error) {
          console.error("Error fetching project details:", error);
        }
      }
    )()

  }, [projectId])


  const getUsers = async () => {
    if (!validateFilters()) return;

    setIsLoading(true);
    setError(null);

    try {
      const result = await projectUsersService.fetchUsers({
        project_id: projectId,
        first_name: filters.first_name,
        last_name: filters.last_name,
        email: filters.email,
        start_date: filters.start_date || undefined,
        end_date: filters.end_date || undefined,
        page: pagination.currentPage,
        page_size: pagination.pageSize,
      });

      if (result && result.status === false) {
        setUsers([]);
        setError('No users found');
      } else {
        setUsers(result.data.users || []);
        setPagination(prev => ({
          ...prev,
          totalItems: result.data.count || 0
        }));
      }
    } catch (err) {
      setError('Error fetching users');
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUsers();
  }, [filterKey]);


  const getUserToken = async (user_id) => {
    try {
      setIsLoadingToken(true);
      setTokenError(null);
      const result = await projectUsersService.fetchUserTokenDetails(user_id);
      console.log("result", result);
      if (result && result.status && result.data) {
        console.log("result", result.data);
        setTokenDetails(result.data);
        setModalOpen(true);
      } else {
        console.log(error)
        setError(result.error || 'Failed to fetch token details');
      }
    } catch (err) {
      setTokenError("Error fetching user token");
    }
    finally {
      setIsLoadingToken(false);
    }
  };
  const closeModal = () => {
    setModalOpen(false);
    setTokenDetails(null);
    setError(null);
  };

  const toggleExportModal = () => {
    setExportModalOpen(!exportModalOpen);
    setExportError("");
  };

  const handleFieldSelection = (fieldKey) => {
    setExportFields((prevFields) =>
      prevFields.includes(fieldKey)
        ? prevFields.filter((key) => key !== fieldKey)
        : [...prevFields, fieldKey]
    );
  };
  const handleSelectAll = () => {
    setExportFields((prevFields) =>
      prevFields.length === availableExportFields.length
        ? []
        : availableExportFields.map((field) => field.key)
    );
  };

  const handleExport = async () => {

    if (users.length === 0) {
      setExportError("No users found");
      return;
    }
    if (exportFields.length === 0) {
      setExportError("Please select at least one field to export.");
      return;
    }
    if (!validateFilters()) return;

    let startDate = filters.start_date ? moment(filters.start_date) : moment().subtract(2, 'months');
    let endDate = filters.end_date ? moment(filters.end_date) : moment();
    try {
      const response = await projectUsersService.exportUsers({
        fields: exportFields,
        project_id: projectId,
        first_name: filters.first_name,
        last_name: filters.last_name,
        email: filters.email,
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName = `Users-${projectDetails.name}-${startDate.format("YYYYMMDD")}-to-${endDate.format("YYYYMMDD")}.xlsx`;
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();

      toggleExportModal();
    } catch (error) {
      console.error("Error exporting user details:", error);
      setExportError("Failed to export user details. Please try again later.");
    }
  };

  const handleSearch = () => {
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  const handleReset = () => {
    setFilters({
      email: "",
      first_name: "",
      last_name: "",
      start_date: null,
      end_date: null,
    });
    setPagination(prev => ({ ...prev, currentPage: 1 }));
    setExportError("");
    setTokenError(null);
    setValidationErrors({});
  };

  const validateFilters = () => {
    const errors = {};

    if (filters.start_date && filters.end_date) {
      const startDate = new Date(filters.start_date);
      const endDate = new Date(filters.end_date);

      if (startDate > endDate) {
        errors.end_date = 'End date must be after start date';
        setValidationErrors(errors);
        setExportError("End date must be after start date.");
        return false;
      }

      const monthDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
      if (monthDiff > 2) {
        errors.end_date = "Date range must be between less than 2 months.";
        setExportError("Date range must be between less than 2 months.");
        setValidationErrors(errors);
        return false;
      }
    }
    setValidationErrors({});
    return true;
  };

  const handleInfoButtonClick = (user) => {
    setSelectedUser(user);
    getUserToken(user.user_id);
  };
  if (!projectId) {
    return <Projects navigate="dashboard/user-profiles" />;;
  } else {
    return (
      <div className="content mt-10">
        <Container fluid className="py-4">
          <Card className="shadow-lg mb-4">
            <CardHeader className="bg-white border-bottom-0 p-4">
              <div className="d-flex align-items-center justify-content-between">
                <CardTitle tag="h2" className="mb-0 d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="me-3 text-primary"
                    style={{ fontSize: '2rem' }}
                  />
                  Users Profile

                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ms-3 text-muted"
                    style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="This dashboard displays a list of users who have logged in to this project using Single Sign-On (SSO)."
                  />
                </CardTitle>

                <div className="text-end">
                  <div className="text-muted" style={{ fontSize: '0.9rem' }}>
                    <div>Project:<strong>{projectDetails.name}</strong></div>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="text"
                        placeholder="Enter Email"
                        value={filters.email}
                        onChange={(e) => setFilters(prev => ({
                          ...prev,
                          email: e.target.value
                        }))}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input
                        type="text"
                        placeholder="Enter First Name"
                        value={filters.first_name}
                        onChange={(e) => setFilters(prev => ({
                          ...prev,
                          first_name: e.target.value
                        }))}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        placeholder="Enter Last Name"
                        value={filters.last_name}
                        onChange={(e) => setFilters(prev => ({
                          ...prev,
                          last_name: e.target.value
                        }))}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Start Date</Label>
                      <Input
                        type="date"
                        value={filters.start_date || ''}
                        onChange={(e) => setFilters(prev => ({
                          ...prev,
                          start_date: e.target.value
                        }))}
                        invalid={!!validationErrors.end_date}
                        style={{
                          borderColor: validationErrors.end_date ? '#dc3545' : '',
                          paddingRight: validationErrors.end_date ? 'calc(1.5em + .75rem)' : '',
                          backgroundPosition: validationErrors.end_date ? 'right calc(.375em + .1875rem) center' : '',
                          backgroundPositionX: validationErrors.end_date ? 'right calc(0.375em + 0.1875rem)' : '',
                          backgroundPositionY: validationErrors.end_date ? 'center' : '',
                          backgroundSize: validationErrors.end_date ? 'calc(.75em + .375rem) calc(.75em + .375rem)' : ''
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>End Date</Label>
                      <Input
                        type="date"
                        value={filters.end_date || ''}
                        onChange={(e) => setFilters(prev => ({
                          ...prev,
                          end_date: e.target.value
                        }))}
                        invalid={!!validationErrors.end_date}
                        style={{
                          borderColor: validationErrors.end_date ? '#dc3545' : '',
                          paddingRight: validationErrors.end_date ? 'calc(1.5em + .75rem)' : '',
                          backgroundPosition: validationErrors.end_date ? 'right calc(.375em + .1875rem) center' : '',
                          backgroundPositionX: validationErrors.end_date ? 'right calc(0.375em + 0.1875rem)' : '',
                          backgroundPositionY: validationErrors.end_date ? 'center' : '',
                          backgroundSize: validationErrors.end_date ? 'calc(.75em + .375rem) calc(.75em + .375rem)' : ''
                        }}
                      />
                      {validationErrors.end_date && (
                        <FormFeedback className="position-absolute" style={{ top: '100%', left: 0 }}>{validationErrors.end_date}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={12} className="d-flex align-items-end justify-content-start flex-wrap gap-2">
                    <div className="d-flex gap-2" style={{ marginBottom: '5px' }}>
                      {/* <Button
                        color="primary"
                        onClick={handleSearch}
                        disabled={isLoading}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faSearch} className="me-2" />
                        Search
                      </Button> */}
                      <Button
                        color="secondary"
                        onClick={handleReset}
                        disabled={isLoading}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faSync} className="me-2" />
                        Reset
                      </Button>
                      <Button color="success" onClick={toggleExportModal} size="sm" >
                        <FontAwesomeIcon icon={faFileExcel} className="me-2" />
                        Excel
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <Card className="shadow-lg">
            <CardBody>
              {pagination.totalItems > 0 ?
                <Row>

                  <Col md="5" className="pt-2">
                    <div style={{ float: 'right' }}>
                      <b>{firstRecord} - {lastRecord} of {pagination.totalItems}</b>
                    </div>
                  </Col>
                  <Col md="6">
                    <div style={{ float: 'left' }}>
                      <Pagination
                        activePage={pagination.currentPage}
                        itemsCountPerPage={pagination.pageSize}
                        totalItemsCount={pagination.totalItems}
                        pageRangeDisplayed={3}
                        onChange={(pageNumber) =>
                          setPagination(prev => ({ ...prev, currentPage: pageNumber }))
                        }
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </Col>
                </Row>

                : null}
              {isLoading ? (
                <div className="text-center py-5">
                  <Spinner color="primary" />
                  <p className="mt-3">Loading Users...</p>
                </div>
              ) : error ? (
                <div className="alert alert-danger text-center">{error}</div>
              ) : users.length === 0 ? (
                <div className="alert alert-info text-center">No users found</div>
              ) : (
                <Table responsive hover striped>
                  <thead className="thead-light text-center">
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Created On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    {users.map((user, index) => (
                      <tr key={user._id}>
                        <td>{firstRecord + index}</td>
                        <td>{`${user.first_name ?? ""} ${user.last_name ?? ""}`}</td>
                        <td>{user.email ?? "-"}</td>
                        <td>
                          <div>
                            {new Date(user.createdAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}
                          </div>
                          <div>
                            {new Date(user.createdAt).toLocaleTimeString('en-US', {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true
                            })}
                          </div>

                        </td>
                        <td>
                          <Button
                            color="info"
                            size="sm"
                            disabled={isLoadingToken}
                            onClick={() => { handleInfoButtonClick(user) }}
                          >
                            {/* {isLoadingToken ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faInfo} />} */}
                            <FontAwesomeIcon icon={faInfo} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>

          <Modal isOpen={modalOpen}>
            <ModalHeader className="text-center">Token Details</ModalHeader>
            <ModalBody>
              {tokenError ? (
                <div className="text-danger text-center">{tokenError}</div>
              ) : tokenDetails ? (
                <div>
                  <h5 className="text-primary text-center mb-3">User Information</h5>
                  {selectedUser && (
                    <div className="d-flex align-items-center mb-4">
                      <img
                        src={selectedUser.picture}
                        alt={`${selectedUser.name}`}
                        className="rounded-circle me-3"
                        style={{ width: '60px', height: '60px' }}
                      />
                      <div>
                        <p className="mb-1"><strong>Name:</strong> {selectedUser.name}</p>
                        <p className="mb-1"><strong>Email:</strong> {selectedUser.email}</p>
                        <p className="mb-0"><strong>Phone:</strong> {selectedUser.phone_number || 'N/A'}</p>
                      </div>
                    </div>
                  )}

                  <h5 className="text-primary text-center mb-3">Token Information</h5>
                  <div>
                    <p><strong>Token Id:</strong> {tokenDetails.token}</p>
                    <p><strong>Total Used:</strong> {tokenDetails.total_used}</p>
                    <p><strong>Created At:</strong> {new Date(tokenDetails.created_at).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}</p>
                    <p><strong>Last Used:</strong> {new Date(tokenDetails.last_used).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}</p>
                    <p><strong>Status:</strong> {tokenDetails.status === 1 ? 'Active' : 'Inactive'}</p>
                  </div>
                </div>
              ) : (
                <div className="text-center text-muted">No token details available.</div>
              )}
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button color="danger" onClick={closeModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={exportModalOpen} centered>
            <ModalHeader className="bg-light text-center">Export User Data</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label className="fw-bold mb-2">Select Fields to Add in Excel File</Label>
                <div className="border rounded p-2 overflow-auto" style={{ maxHeight: '200px' }}>

                  <div className="fs mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="selectAll"
                      onChange={handleSelectAll}
                      checked={exportFields.length === availableExportFields.length}
                    />
                    <label className="form-check-label ms-2" htmlFor="selectAll">
                      Select All
                    </label>
                  </div>

                  {availableExportFields.map((field) => (
                    <div className="fs" key={field.key}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={field.key}
                        onChange={() => handleFieldSelection(field.key)}
                        checked={exportFields.includes(field.key)}
                      />
                      <label className="form-check-label ms-2" htmlFor={field.key}>
                        {field.label}
                      </label>
                    </div>
                  ))}
                </div>
              </FormGroup>
              {exportError && <div className="text-danger text-center mt-3 small">{exportError}</div>}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="primary" onClick={handleExport}>
                Export
              </Button>
              <Button color="secondary" onClick={toggleExportModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>


        </Container>
      </div>
    );
  }
};

export default UserProfileDashboard;