import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
const Dashboard = () => {
  const [cmsRequests, setCmsRequests] = useState([]);
  const [showApprovalsModal, setShowApprovalsModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [approvalModal, setApprovalModal] = useState(false);
  const [selectedApproval, setSelectedApproval] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    axios.get('/api/cms-requests')
      .then(response => setCmsRequests(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleShowApprovals = (request) => {
    setSelectedRequest(request);
    setShowApprovalsModal(true);
  };

  const handleApprovalClick = (approval, requestId, approvalIndex) => {
    setSelectedApproval({ approval, requestId, approvalIndex });
    setComment(approval.comment || '');
    setShowApprovalsModal(false);
    setApprovalModal(true);
  };

  const handleApproval = (requestId, approvalIndex, status) => {
    const updatedApproval = {
      requestId,
      approvalIndex,
      status,
      comment
    };

    axios.patch('/api/cms-requests/approval', updatedApproval)
      .then(() => {
        setApprovalModal(false);
        toast.success("Approval Updated Successfully")

      })
      .catch((error) => {
        console.error('Error updating approval:', error)
        toast.error("Error Updating Approval")

      });
  };

  const getStatusBadge = (status) => {
    const statusColors = {
      pending: "warning",
      approved: "success",
      rejected: "danger"
    };

    return (
      <Badge color={statusColors[status]} className="px-3 py-2" pill>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  const styles = {
    container: {
      padding: '2rem'
    },
    cardTitle: {
      fontSize: '1.5rem',
      fontWeight: '600',
      marginBottom: '0',
      textAlign: 'center',
    },
    tableHeader: {
      backgroundColor: '#f8f9fa',
      fontWeight: '600'
    },
    approvalItem: {
      cursor: 'pointer',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: '#f8f9fa'
      }
    },
    modalBody: {
      maxHeight: '70vh',
      overflowY: 'auto'
    },
    infoSection: {
      backgroundColor: '#f8f9fa',
      padding: '1rem',
      borderRadius: '0.375rem',
      marginBottom: '1rem'
    },
    label: {
      fontWeight: '600',
      marginBottom: '0.5rem'
    },
    line: {
      borderTop: '2px solid #ddd',
      margin: '1rem 0'
    },
    modalHeader: {
      fontWeight: '600',
    },
    showButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconButton: {
      padding: '0.5rem 0.5rem',
      fontSize: '1.15rem',
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return (
    <div className="content" style={styles.container}>
      <Card className="shadow-sm">
        <CardHeader>
          <h3 style={styles.cardTitle}>CMS YouTube Approvals</h3>
          <div style={styles.line}></div>
        </CardHeader>

        <CardBody>
          <Table hover responsive>
            <thead>
              <tr style={styles.tableHeader}>
                <th className="w-10">#</th>
                <th>Project ID</th>
                <th>Authorized Channels</th>
                <th>Authorized Playlists</th>
                <th className="w-25">Approvals</th>
              </tr>
            </thead>
            <tbody>
              {cmsRequests.map((request, index) => (
                <tr key={request._id}>
                  <td>{index + 1}</td>
                  <td className="font-weight-medium">{request.project_id.name}</td>
                  <td>{request.authorized_channel_ids.join(', ')}</td>
                  <td>{request.authorized_playlist_ids.join(', ')}</td>
                  <td>
                    <Button
                      color="primary"
                      outline
                      onClick={() => handleShowApprovals(request)}
                    // style={styles.iconButton}
                    >
                      Show Approvals
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal isOpen={showApprovalsModal} toggle={() => setShowApprovalsModal(false)} size="md">
        <ModalHeader style={styles.modalHeader}>
          Approvals List
        </ModalHeader>
        <ModalBody style={styles.modalBody}>
          <ListGroup>
            {selectedRequest?.approvals
              .map((approval, index) => (
                <ListGroupItem
                  key={index}
                  action
                  onClick={() => handleApprovalClick(approval, selectedRequest._id, index)}
                  className="d-flex justify-content-between align-items-center"
                  style={styles.approvalItem}
                >
                  <span className="font-weight-medium">{`${index + 1})`}</span>
                  <span className="font-weight-medium">{moment(approval.created_at).format('YYYY-MM-DD hh:mm:ss a')}</span>
                  {getStatusBadge(approval.status)}
                </ListGroupItem>
              ))}
          </ListGroup>
        </ModalBody>
      </Modal>

      <Modal isOpen={approvalModal} toggle={() => setApprovalModal(false)} size="lg">
        <ModalHeader style={styles.modalHeader}>
          Approval Details
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label style={styles.label}>Index</Label>
              <div>
                {selectedApproval && `${selectedApproval.approvalIndex + 1}`}
              </div>
            </FormGroup>

            <FormGroup>
              <Label style={styles.label}>Created At </Label>
              <div>
                {selectedApproval && `${moment(selectedApproval.approval.created_at).format('YYYY-MM-DD hh:mm:ss a')}`}
              </div>
            </FormGroup>
            <FormGroup>
              <Label style={styles.label}>Status</Label>
              <div>
                {selectedApproval && getStatusBadge(selectedApproval.approval.status)}
              </div>
            </FormGroup>

            <FormGroup>
              <Label style={styles.label}>Channel IDs</Label>
              <div style={styles.infoSection}>
                {selectedApproval?.approval?.channel_ids?.map((channel, index) => (
                  <div key={index} className="mb-1">{channel}</div>
                ))}
              </div>
            </FormGroup>

            <FormGroup>
              <Label style={styles.label}>Playlist IDs</Label>
              <div style={styles.infoSection}>
                {selectedApproval?.approval?.playlist_ids?.map((playlist, index) => (
                  <div key={index} className="mb-1">{playlist}</div>
                ))}
              </div>
            </FormGroup>

            <FormGroup>
              <Label for="comment" style={styles.label}>Comment</Label>
              <Input
                type="textarea"
                id="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows="4"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            color="danger"
            onClick={() => handleApproval(selectedApproval.requestId, selectedApproval.approvalIndex, 'rejected')}
          >
            Reject
          </Button>
          <Button
            color="success"
            onClick={() => handleApproval(selectedApproval.requestId, selectedApproval.approvalIndex, 'approved')}
          >
            Approve
          </Button>
        </ModalFooter>
      </Modal>
      <ToastContainer autoClose={1000} position='bottom-right' />
    </div>
  );
};

export default Dashboard;
