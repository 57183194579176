
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Alert, Row, Col, CardHeader, CardTitle, Form, Label, FormGroup, Button } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import contentService from "../../services/api/content-service";
import gamesService from '../../services/api/games-service';
import { useHistory } from 'react-router-dom';
const customStyles = {
    control: (provided,state) => ({
        ...provided,
        borderColor: '#007bff',
        borderRadius: '12px',
        border: state.isFocused ? '2px solid #11cdef' : '1px solid #e9ecef',
        boxShadow: state.isFocused ? '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#11cdef' : '#ced4da'

             },
        padding: '4px',
        borderRadius: '8px',
        width: '80%',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '0.5rem',
        zIndex: 9999,
        width: '80%'
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#e9f5ff' : 'white',
        color: '#333',
        '&:hover': { backgroundColor: '#dceeff' },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#6c757d',
        fontSize: '14px',
    }),
};



const MAX_ARTICLES = 50;

const CreateGames = (props) => {
    const history = useHistory();
    const projectId = new URLSearchParams(props.location.search).get("projectid");
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [articles, setArticles] = useState([]);
    const [alert, setAlert] = useState({ show: false, type: '', message: '' });
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        (async () => {
            let categoryData = await contentService.getCategories(projectId);
            if (categoryData.status) {
                let allCategories = categoryData.data;
                let categoryArray = [];
                if (allCategories.length) {
                    allCategories.forEach(function (categoryDetails) {
                        categoryArray.push({
                            value: categoryDetails._id,
                            label: categoryDetails.name
                        });

                        if (categoryDetails.sub_categories && categoryDetails.sub_categories.length) {
                            let subCategories = categoryDetails.sub_categories;
                            subCategories.forEach(function (subCategoryDetails) {
                                categoryArray.push({
                                    value: subCategoryDetails._id,
                                    label: subCategoryDetails.name
                                });
                            });
                        }
                    });
                    setCategories(categoryArray);
                }
            }
        })();
    }, [projectId]);
    useEffect(() => {
        if (selectedCategory) {
            loadArticles('');
        }
    }, [selectedCategory]);

    const handleCategorySelect = (category) => {
        setSelectedArticle(null);
        setSelectedCategory(category ? [category.value] : []);
    };

    const handleSelect = (article) => {
        setSelectedArticle(article);
        console.log("Selected Article:", article);
    };

    const loadArticles = async (inputValue) => {
        try {
            const filters = {
                title: inputValue || '',
                categoryids: selectedCategory,
                order_by: "",
            };

            const response = await gamesService.getArticleTitle(projectId, filters, 1, MAX_ARTICLES);
            setArticles(response?.data?.articles || []);
            return response?.data?.articles?.map((article) => ({
                value: article.id,
                label: article.title,
            })) || [];
        } catch (error) {
            console.error("Error fetching articles:", error);
            return [];
        }
    };
    const updateGamesStatus = async () => {
        try {
            setIsLoading(true);
            console.log("updating game status")
            const gamesStatus = await contentService.updateGamesStatus({
                project_id: projectId,
                article_id: selectedArticle.value,
                action: 'generate'
            })
            if (gamesStatus && gamesStatus.data && gamesStatus.data.status) {
                setAlert({
                    show: true,
                    type: 'success',
                    message: 'Job to generate games sent successfully!'
                });
            }
            else {
                setAlert({
                    show: true,
                    type: 'danger',
                    message: gamesStatus.data.error || 'An error occurred',
                });
            }
        } catch (error) {
            setAlert({
                show: true,
                type: 'danger',
                message: error || 'An unexpected error occurred',
            });
        } finally {
            setIsLoading(false);
            setSelectedArticle(null);
            setSelectedCategory(null);
            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);
        }
    }
    return (
        <div className="content mx-auto px-4 py-6">
            <Card
                className="card-signup text-center shadow-lg"
                style={{
                    borderRadius: '20px',
                    border: 'none',
                    backgroundColor: '#f8f9fe'
                }}
            >
                <CardHeader
                    className=" text-black"
                    style={{
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        padding: '1rem'
                    }}
                >
                    <CardTitle tag="h3" className="text-black mb-0">
                        Create Games
                    </CardTitle>
                </CardHeader>
                <CardBody className="p-6">
                    <Form>
                        <Row className="mb-4">
                            <Label sm="3" className="text-dark font-weight-bold text-lg"
                                style={{
                                    fontSize: '1.05rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                Select Category</Label>
                            <Col sm="9">
                                <FormGroup>
                                    <Select
                                        options={categories}
                                        value={selectedCategory ? categories.find(category => category.value === selectedCategory) : null} onChange={handleCategorySelect}
                                        placeholder="Select a category..."
                                        styles={customStyles}
                                    />
                                </FormGroup></Col>

                        </Row>

                        {selectedCategory && (
                            <Row className="mb-4">
                                <Label
                                    sm="3"
                                    className="text-dark font-weight-bold text-lg"
                                    style={{
                                        fontSize: '1.05rem',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Select Article</Label>
                                <Col sm="9">
                                    <FormGroup>
                                        <AsyncSelect
                                            cacheOptions
                                            loadOptions={loadArticles}
                                            value={selectedArticle}
                                            placeholder="Search for articles..."
                                            onChange={handleSelect}
                                            defaultOptions={articles.map(article => ({
                                                value: article.id,
                                                label: article.title
                                            }))}
                                            styles={customStyles}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        )}
                    </Form>
                    <Row className="mb-4">
                        <Col className="d-flex justify-content-center gap-3">
                            <Button
                                className={`btn btn-primary px-6 py-2 rounded-lg transition-all duration-300 ${!selectedArticle || isLoading
                                    ? 'opacity-50 cursor-not-allowed'
                                    : 'hover:bg-blue-600'
                                    }`}
                                onClick={updateGamesStatus}
                                disabled={!selectedArticle || isLoading}
                            >
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </Button>

                            <Button
                                className="btn btn-secondary px-6 py-2 rounded-lg hover:bg-gray-200 transition-all duration-300"
                                onClick={() => history.goBack()}
                            >
                                Back
                            </Button>
                        </Col>
                    </Row>


                    <div>
                        {alert.show && (
                            <Alert
                                color={alert.type}
                                className="mt-3 mt-4 text-center rounded-lg"
                                style={{ borderRadius: '10px' }}
                            >
                                {alert.message}
                            </Alert>
                        )}
                    </div>
                </CardBody>
            </Card>

        </div>
    );
};

export default CreateGames;
