import React, { useEffect, useState } from 'react'
import { Spinner, Button, Form, FormGroup, Label, Input, Alert, Container, Card, Row, CardHeader, CardTitle, CardBody, Col } from 'reactstrap';
import Switch from "react-bootstrap-switch";
import Select from 'react-select';
import gamesService from '../../services/api/games-service';
import contentService from "../../services/api/content-service";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const GamesSettings = (props) => {
    const projectId = new URLSearchParams(props.location.search).get('projectid');
    const [showLoader, setShowLoader] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [alert, setAlert] = useState({ show: false, type: '', message: '' });
    const [categories, setCategories] = useState([])
    const [formdetails, setFormDetails] = useState({
        project_id: projectId,
        status: false,
        category_ids: [],
        enabled_games: [],
    })
    const AVAILABLE_GAMES = [
        { value: 'quiz', label: 'Quiz' },
        { value: 'headline_scramble', label: 'Headline Scramble' },
        { value: 'hangman', label: 'Hangman' },
        { value: 'crossword', label: 'Crossword' },
        { value: 'poll', label: 'Poll' }
    ]

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '12px',
            border: state.isFocused ? '2px solid #11cdef' : '1px solid #e9ecef',
            boxShadow: state.isFocused ? '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)' : 'none',
            '&:hover': {
                borderColor: state.isFocused ? '#11cdef' : '#ced4da'
            },
            padding: '2px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#11cdef',
            color: 'white',
            borderRadius: '12px'
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white'
        })
    };

    useEffect(() => {
        fetchGameSettings();
        fetchCategories();
    }, [projectId]);

    const fetchGameSettings = async () => {
        try {
            const gamesDetails = await gamesService.getGamesSettings({ project_id: projectId });
            if (gamesDetails.status && gamesDetails.data) {
                setFormDetails((prevDetails) => ({
                    ...prevDetails,
                    status: gamesDetails.data.status,
                    category_ids: gamesDetails.data.category_ids || [],
                    enabled_games: gamesDetails.data.enabled_games || [],
                }));
            }
        } catch (error) {
            setAlert({
                show: true,
                type: 'danger',
                message: 'Failed to fetch game settings. Please try again later.',
            });
        }
    };

    const fetchCategories = async () => {
        try {
            const categoryData = await contentService.getCategories(projectId);
            if (categoryData.status && categoryData.data?.length) {
                const categoryArray = categoryData.data.flatMap((category) => {
                    const subCategories = category.sub_categories?.map((subCategory) => ({
                        value: subCategory._id.toString(),
                        label: subCategory.name,
                    })) || [];
                    return [
                        { value: category._id.toString(), label: category.name },
                        ...subCategories,
                    ];
                });
                setCategories(categoryArray);
            }
        } catch (error) {
            setAlert({
                show: true,
                type: 'danger',
                message: 'Failed to fetch categories. Please try again later.',
            });
        }
    };

    const handleSwitchChange = (fieldname, fieldvalue) => {
        setFormDetails({
            ...formdetails,
            [fieldname]: fieldvalue,
        });
    }

    const handleMultiSelectChange = (fieldname, selectedOptions) => {
        const validOptions = Array.isArray(selectedOptions) ? selectedOptions : [];
        setFormDetails((prevDetails) => ({
            ...prevDetails,
            [fieldname]: validOptions.map((option) => option.value) || [],
        }));
    };

    const validateForm = async () => {
        if (formdetails.status && formdetails.category_ids.length === 0) {
            toast.error("Please select at least one category when games are enabled!")
            return;
        }

        setShowLoader(true)
        setShowSaveButton(false)
        try {
            let saveGamesSettings = await gamesService.saveGamesSettings(formdetails);
            if (!saveGamesSettings.status && saveGamesSettings.error.errorCode === 403) {
                // context.setIsAuthorized(context);
            }
            else if (saveGamesSettings.status) {
                setAlert({
                    show: true,
                    type: 'success',
                    message: 'Games settings were saved successfully!'
                });
            } else {
                setAlert({
                    show: true,
                    type: 'danger',
                    message: 'Games settings could not be saved, Please try again.'
                });
            }
        } catch (error) {
            setAlert({
                show: true,
                type: 'danger',
                message: 'An error occurred while saving the settings. Please try again later.'
            });
        } finally {
            setShowLoader(false);
            setShowSaveButton(true);
        }

        setTimeout(() => {
            setAlert({ show: false, type: '', message: '' });
        }, 3000);
    }

    return (
        <div className='content'>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Card 
                            className="card-signup text-center shadow-lg" 
                            style={{
                                borderRadius: '20px',
                                border: 'none',
                                backgroundColor: '#f8f9fe'
                            }}
                        >
                            <CardHeader 
                                className=" text-black" 
                                style={{
                                    borderTopLeftRadius: '20px',
                                    borderTopRightRadius: '20px',
                                    padding: '1rem'
                                }}
                            >
                                <CardTitle tag="h3" className="text-black mb-0">
                                    Games Configuration
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    className="form-horizontal"
                                    action=""
                                    id="projectSetting"
                                    method="POST"
                                >
                                    <Row className="mb-4 align-items-center">
                                        <Label sm="3" className="text-dark font-weight-bold">Enable Games</Label>
                                        <Col sm="9" className="d-flex justify-content-start">
                                            <FormGroup className="mb-0">
                                                <Switch
                                                    name="enable_games"
                                                    offColor="success"
                                                    offText={<i className="nc-icon nc-simple-remove" />}
                                                    onColor="success"
                                                    onText={<i className="nc-icon nc-check-2" />}
                                                    value={formdetails.status}
                                                    onChange={(value, enabled) =>
                                                        handleSwitchChange("status", enabled)
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {formdetails.status && (
                                        <>
                                            <Row className="mb-4">
                                                <Label sm="3" className="text-dark font-weight-bold">Select Categories</Label>
                                                <Col sm="9">
                                                    <FormGroup>
                                                        <Select
                                                            isMulti
                                                            options={categories}
                                                            styles={customSelectStyles}
                                                            value={categories.filter(category =>
                                                                formdetails.category_ids.includes(category.value)
                                                            )}
                                                            onChange={(selectedOption) => handleMultiSelectChange('category_ids', selectedOption)}
                                                            placeholder="Select categories"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="mb-4">
                                                <Label sm="3" className="text-dark font-weight-bold">Select Games</Label>
                                                <Col sm="9">
                                                    <FormGroup>
                                                        <Select
                                                            isMulti
                                                            options={AVAILABLE_GAMES}
                                                            styles={customSelectStyles}
                                                            value={AVAILABLE_GAMES.filter(game =>
                                                                formdetails.enabled_games.includes(game.value)
                                                            )}
                                                            onChange={(selectedOption) => handleMultiSelectChange('enabled_games', selectedOption)}
                                                            placeholder="Select Games"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                    {showSaveButton && (
                                        <Button
                                            className="btn-round shadow-sm"
                                            color="primary"
                                            type="button"
                                            onClick={validateForm}
                                            style={{
                                                borderRadius: '30px',
                                                padding: '10px 25px',
                                                transition: 'all 0.3s ease'
                                            }}
                                        >
                                            Save Settings
                                        </Button>
                                    )}

                                    {showLoader && (
                                        <div className="text-center mt-3">
                                            <Spinner color="primary" />
                                        </div>
                                    )}

                                    {alert.show && (
                                        <Alert 
                                            color={alert.type} 
                                            className="mt-3"
                                            style={{ borderRadius: '10px' }}
                                        >
                                            {alert.message}
                                        </Alert>
                                    )}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer 
                autoClose={3000} 
                position='bottom-right' 
                newestOnTop
                closeOnClick
                pauseOnHover
            />
        </div>
    )
}

export default GamesSettings